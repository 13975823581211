<template>
    <div>
        <div class="head-container">
            <div>
                <div class="head-text-main">
                    Relevant Projects
                </div>
            </div>
        </div>
        <v-container class="main-text text-container">
            <v-row>
                <v-col>
                    <h2 class="mt-8 mb-4"><strong>Papers relevant to the DSS NUTRISENSE published by the team of <span class="green-text">NutriSense</span></strong></h2>
                    <p>
                        <ol>
                            <li>
                                Savvas, D., Gruda, N., 2018. Application of soilless culture technologies in the modern greenhouse industry - A review. European Journal of Horticultural Science 83, 280-293. <a href="https://doi.org/10.17660/eJHS.2018/83.5.2" target="_blank">https://doi.org/10.17660/eJHS.2018/83.5.2</a>
                            </li>
                            <li>
                                Neocleous, D., and Savvas, D. 2022. Validating a smart nutrient solution replenishment strategy to save water and nutrients in hydroponic crops. Frontiers in Environmental Sciences. 10:965964. <a href="https://doi.org/10.3389/fenvs.2022.965964" target="_blank">https://doi.org/10.3389/fenvs.2022.965964</a>
                            </li>
                            <li>
                                Savvas, D., Giannothanasis, E., Ntanasi, Th., Karavidas, I., Drakatos, S., Panagiotakis, I., Neocleous, D., Ntatsi, G., 2023. Development and validation of a decision support system to maintain optimal nutrient levels in crops grown in closed-loop soilless systems. Agricultural Water Management 285, 108373. <a href="https://doi.org/10.1016/j.agwat.2023.108373" target="_blank">https://doi.org/10.1016/j.agwat.2023.108373</a>
                            </li>
                            <li>
                                Savvas, D.; Giannothanasis, E.; Ntanasi, T.; Karavidas, I.; Ntatsi, G., 2024. State of the art and new technologies to recycle the fertigation effluents in closed soilless cropping systems aiming to maximise water and nutrient use efficiency in greenhouse crops. Agronomy 14, 61. <a href="https://doi.org/10.3390/agronomy14010061" target="_blank">https://doi.org/10.3390/agronomy14010061</a>.
                            </li>
                        </ol>
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    export default {
        name: 'App',
        components: {
        },
        data: () => ({
        }),
        computed: {
        }
    }
</script>
<style scoped>
.heading {
    color: #5FA936;
    font-size: 36px;
    font-weight: 700;
}

.head-container{
    background-image: url("~@/assets/about_a_head.png");
    height: 380px;
    width: 100%;
    z-index: 0;
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-shadow: 0px 0px 20px #0304036b;
    margin-top: -50px;
}

.head-text-main {
    font-weight: 700;
    font-size: 96px;
}

.head-text-secondary {
    font-size: 32px;
}

.main-text {
    color: #646464;
    font-size: 16px;
    line-height: 26px;
}
.text-container {
    max-width: 65vw;
}

.green-text {
    color: #006600;
    font-weight: bold;
}
ol {
    color: #383838;
    font-weight: normal;
}
.spacer {
    display: inline-block;
    width: 40px;
}
strong {
    color: #383838;
}
.green-text {
    color: #006600;
    font-weight: bold;
}
</style>
